html,
body,
#root {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	.App > #main {
		background-image: url("../../images/NMMP - Wallpaper Pattern.png");
	}
}

.card {
	box-shadow: 0 16px 30px -5px rgba(0, 0, 0, 0.4);
}
